'use client';

import { useEffect } from 'react';
import Link from 'next/link';
import Image from 'next/image';

export default function Error({
    error,
    reset,
}: {
    error: Error & { digest?: string };
    reset: () => void;
}) {
    useEffect(() => {
        // Log the error to your error reporting service
        console.error(error);
    }, [error]);

    return (
        <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex items-center justify-center px-4">
            <div className="max-w-lg w-full text-center">
                {/* Error Illustration */}
                <div className="mb-8">
                        <svg 
                            className="w-24 h-24 text-primary mx-auto"
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24"
                        >
                            <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth="1.5" 
                                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                            />
                    </svg>
                </div>

                {/* Error Message */}
                <h1 className="text-6xl font-bold text-gray-900 mb-4">
                    500
                </h1>
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                    Internal Server Error
                </h2>
                <p className="text-gray-600 mb-8">
                    We're sorry, but something went wrong on our end. Our team has been notified and we're working to fix the issue.
                </p>

                {/* Action Buttons */}
                <div className="flex flex-col sm:flex-row gap-4 justify-center">
                    <button
                        onClick={reset}
                        className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:bg-primary/90 transition-colors"
                    >
                        Try Again
                    </button>
                    <Link
                        href="/"
                        className="inline-flex items-center justify-center px-6 py-3 border border-gray-300 dark:border-gray-600 text-base font-medium rounded-md text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors"
                    >
                        Go to Homepage
                    </Link>
                </div>

                {/* Support Information */}
                <div className="mt-12 p-6 bg-white dark:bg-gray-800 rounded-lg shadow-sm">
                    <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                        Need Assistance?
                    </h2>
                    <p className="text-gray-600 dark:text-gray-300 mb-4">
                        If this issue persists, please contact our support team:
                    </p>
                    <div className="flex flex-col sm:flex-row gap-4 justify-center">
                        <Link
                            href="/contact"
                            className="text-primary dark:text-blue-400 hover:underline"
                        >
                            Contact Support
                        </Link>
                        <a
                            href="mailto:support@your-domain.com"
                            className="text-primary dark:text-blue-400 hover:underline"
                        >
                            info@continuationtrade.com
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
} 